import React, { useEffect, useState } from 'react';
import { Button, Card, Collapse, Form } from 'react-bootstrap';
import { api, superAdminReqConfig } from '../../include/api';
import { ERROR, SUCCESS } from '../../include/constant';
import { useNotification } from '../../Toast/ToastProvider';
import SuperAdminHeader from './Header';
import LoyaltyPointsSettings from './LoyaltyPointsSettings';

const SettingsCard = ({ index, formData, handleChange, handleSubmit, open, toggleOpen, header, data, toast, onEditDelete, setCards, cards }) => {
    formData.head = header;

    const handleSetEditValues = (id) => {
        let editObj = data.find(d => String(d.id) === String(id));
        console.log(editObj);

        // formData.head = editObj.head;
        // formData.key = editObj.key;
        // formData.value = editObj.value;
        // formData.id = editObj.id; // Store the id for updating

        setCards(
            cards.map((card, i) =>
                i === index
                    ? { ...card, formData: editObj }
                    : card
            )
        );
    };

    return (
        <Card className="m-3">
            <Card.Header>
                <Button
                    className='fs-5 fw-bold'
                    variant="link"
                    onClick={() => toggleOpen(index)}
                    aria-controls={`collapse-form-${index}`}
                    aria-expanded={open}
                >
                    {open ? header : header}
                </Button>
            </Card.Header>
            <Collapse in={open}>
                <div id={`collapse-form-${index}`}>
                    <div className='row ms-2'>
                        {data?.map((d, i) => (
                            <SettingsView
                                settings={d}
                                key={i}
                                onEditDelete={onEditDelete}
                                toast={toast}
                                handleSetEditValues={() => handleSetEditValues(d.id)} // Pass the edit function here
                            />
                        ))}
                    </div>
                    <Card.Body>
                        <Form onSubmit={(e) => handleSubmit(e, index)}>
                            <Form.Group controlId={`formHead-${index}`}>
                                <Form.Label>Head</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="head"
                                    value={formData?.head || ''}
                                    onChange={(e) => handleChange(e, index)}
                                    placeholder="Enter head"
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId={`formKey-${index}`} className="mt-3">
                                <Form.Label>Key</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="key"
                                    value={formData?.key || ''}
                                    onChange={(e) => handleChange(e, index)}
                                    placeholder="Enter key"
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId={`formValue-${index}`} className="mt-3">
                                <Form.Label>Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="value"
                                    value={formData?.value || ''}
                                    onChange={(e) => handleChange(e, index)}
                                    placeholder="Enter value"
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-4">
                                {formData?.id ? "Update" : "Save"}
                            </Button>
                        </Form>
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};

//card component
const SettingsView = ({ settings, onEditDelete, toast, handleSetEditValues }) => {
    // const toast = useNotification()

    const handleDeleteSettings = () => {

        if (settings.id) {
            api.delete('/settings/?id=' + settings.id, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message })
                    onEditDelete()
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        }
    }

    const handleEditSettings = () => {
        handleSetEditValues(); // Call this function when the edit button is clicked
    };

    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm p-2">
            <h6 className="d-flex align-items-center justify-content-between fw-bold">
                <div className="text-truncate">
                    <i className={`fas fa-circle blink ${'text-success'}`} style={{ fontSize: '.7rem' }} />
                    <span className="ps-2">{settings.head}</span>
                </div>
                <span className="small d-flex align-items-center hover-underline">
                    <i className="fas fa-pencil-alt fa-sm p-2 text-primary" onClick={handleEditSettings} />
                    <i className="fas fa-trash-alt fa-sm ps-2 text-danger" onClick={handleDeleteSettings} />
                </span>
            </h6>
            <hr className="my-2" />
            <p className="small mb-1 text-muted">
                <i className="fas fa-key fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{settings.key}</span>
            </p>
            <p className="small mb-1 text-muted">
                <i className="fas fa-square-check fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{settings.value}</span>
            </p>
        </div>
    </div>
}

const SettingsList = () => {
    const toast = useNotification()
    const [settingsData, setSettingsData] = useState([])
    const [cards, setCards] = useState([])

    useEffect(() => {
        // Extract unique 'head' values from settingsData
        let heads = [...new Set(settingsData.map(s => s.head))].sort((a, b) => a.localeCompare(b));

        console.log(heads);

        let array = [];
        if (heads.length > 2) {
            heads.forEach(h => {
                array.push({ head: h, open: false, formData: { head: '', key: '', value: '' } });
            });
        } else {
            array.push(
                { head: 'Shipping Methods', open: false, formData: { head: '', key: '', value: '' } },
                { head: 'Payment Methods', open: false, formData: { head: '', key: '', value: '' } },
            )
        }

        setCards(array);
    }, [settingsData]);


    // const [cards, setCards] = useState([
    //     { head: 'Shipping Methods', open: false, formData: { head: '', key: '', value: '' } },
    //     { head: 'Payment Methods', open: false, formData: { head: '', key: '', value: '' } },
    //     // { head: 'Shipping Methods', open: false, formData: { head: '', key: '', value: '' } },
    // ]);
    const getSettingsdata = () => {
        api.get('/settings/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSettingsData(response.data.data)
            }
        }).catch(error => {
            setSettingsData([])
        })
    }
    // console.log(settingsData);


    useEffect(() => {
        getSettingsdata()
    }, [])

    const toggleOpen = (index) => {
        setCards(
            cards.map((card, i) =>
                i === index ? { ...card, open: !card.open } : card
            )
        );
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        setCards(
            cards.map((card, i) =>
                i === index
                    ? { ...card, formData: { ...card.formData, [name]: value } }
                    : card
            )
        );
    };

    const handleSubmit = (e, index) => {
        e.preventDefault();
        const formData = cards[index].formData;

        if (formData.id) {
            // Update existing setting (PUT request)
            api.put(`/settings/?id=${formData.id}`, formData, superAdminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        getSettingsdata();
                        toast({ type: SUCCESS, message: response.data.message });
                        // Reset the form after update
                        setCards(
                            cards.map((card, i) =>
                                i === index
                                    ? { ...card, formData: { head: '', key: '', value: '' } }
                                    : card
                            )
                        );
                    }
                })
                .catch(error => {
                    error.response
                        ? toast({ type: ERROR, message: error.response.data.message })
                        : toast({ type: ERROR, message: error.message });
                });
        } else {
            // Create new setting (POST request)
            api.post('/settings/', formData, superAdminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        getSettingsdata();
                        toast({ type: SUCCESS, message: response.data.message });
                        // Reset the form after submission
                        setCards(
                            cards.map((card, i) =>
                                i === index
                                    ? { ...card, formData: { head: '', key: '', value: '' } }
                                    : card
                            )
                        );
                    }
                })
                .catch(error => {
                    error.response
                        ? toast({ type: ERROR, message: error.response.data.message })
                        : toast({ type: ERROR, message: error.message });
                });
        }
    };

    console.log(cards);


    return (<SuperAdminHeader >
        <div className="fixed-top-bar" style={{ marginBottom: "10%" }}>
            <div>
                {cards?.map((card, index) => {
                    let data = settingsData?.filter(f => f.head === card.head)
                    return < SettingsCard
                        cards={cards}
                        setCards={setCards}
                        toast={toast}
                        data={data}
                        key={index}
                        index={index}
                        header={card.head}
                        formData={card.formData}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        open={card.open}
                        toggleOpen={toggleOpen}
                        onEditDelete={getSettingsdata}
                    />
                })}
                <LoyaltyPointsSettings />
            </div>
        </div>
    </SuperAdminHeader>
    );
};

export default SettingsList;
