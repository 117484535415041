import React, { useState } from "react";
import { toast } from "react-toastify";
import { api, superAdminReqConfig } from "../../include/api";
import SuperAdminHeader from "./Header";

const SuperNotificationForm = () => {
    const [formData, setFormData] = useState({
        title: "",
        body: "",
        imageFile: null,
        previewUrl: null,
    });
    const [errors, setErrors] = useState({
        title: "",
        body: "",
    });
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState(""); // Added success message state

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Clear error messages when the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value ? "" : prevErrors[name],
        }));
    };

    // Handle file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData((prevData) => ({
                ...prevData,
                imageFile: file,
                previewUrl: URL.createObjectURL(file),
            }));
        }
    };

    // Clear form fields after successful save
    const clearFields = () => {
        setFormData({
            title: "",
            body: "",
            imageFile: null,
            previewUrl: null,
        });
        setErrors({
            title: "",
            body: "",
        });
    };

    // Handle form submission
    const handleSave = () => {
        let valid = true;

        // Validate title and body
        if (!formData.title.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: "Title is required",
            }));
            valid = false;
        }

        if (!formData.body.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                body: "Body text is required",
            }));
            valid = false;
        }

        if (valid) {
            setSaving(true);

            const dataToSend = new FormData();
            dataToSend.append("title", formData.title);
            dataToSend.append("body", formData.body);
            if (formData.imageFile) {
                dataToSend.append("imageFile", formData.imageFile);
            }

            api
                .post("/notification/", dataToSend, superAdminReqConfig())
                .then((response) => {
                    if (response.status === 200) {
                        toast.success(response.data.message || "Notification is saved!"); // Toast notification
                        setSuccessMessage("Notification is saved!"); // Set success message
                        clearFields(); // Clear fields after success
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message || "Something went wrong");
                    } else {
                        toast.error(error.message || "Network error");
                    }
                })
                .finally(() => setSaving(false));
        }
    };

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="container my-3">
                    <h2 className="mb-4">Push Notification</h2>

                    {/* Success Message */}
                    {successMessage && (
                        <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>
                    )}

                    <form className="mb-5">
                        {/* Title Input */}
                        <div className="mb-3">
                            <label htmlFor="title" className="form-label">
                                Title
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="Enter title"
                            />
                            {errors.title && (
                                <div className="text-danger small">{errors.title}</div>
                            )}
                        </div>

                        {/* Body Input */}
                        <div className="mb-3">
                            <label htmlFor="body" className="form-label">
                                Body
                            </label>
                            <textarea
                                className="form-control"
                                id="body"
                                name="body"
                                value={formData.body}
                                onChange={handleChange}
                                placeholder="Enter body text"
                                rows="3"
                            />
                            {errors.body && (
                                <div className="text-danger small">{errors.body}</div>
                            )}
                        </div>

                        {/* Image File Input */}
                        <div className="mb-3">
                            <label htmlFor="imageFile" className="form-label">
                                Upload Image
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="imageFile"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                            {formData.previewUrl && (
                                <div className="mt-3">
                                    <p>Image Preview:</p>
                                    <img
                                        src={formData.previewUrl}
                                        alt="Preview"
                                        style={{ width: "100%", maxWidth: "300px", height: "300px" }}
                                    />
                                </div>
                            )}
                        </div>

                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                            disabled={saving}
                        >
                            {saving ? "Saving..." : "Save"}
                        </button>
                    </form>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default SuperNotificationForm;
