import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import Flyout from "../common/Flyout";
import FilterModal from "./FilterModal";
import SuperAdminHeader from "./Header";

const Shipping = ({ from }) => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [shippingData, setShippingData] = useState([]);
    const [trackingData, setTrackingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [invObj, setInvObj] = useState(null);
    const [shippingObj, setShippingObj] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({ fromDate: "", toDate: "", orderId: "", product: "", paymentMethod: "", status: "" });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [selectQuery, setSelectQuery] = useState("");
    const [customer, setCustomer] = useState([]);
    const [settings, setSettings] = useState([]);
    const prevQueryRef = useRef();

    const handleSelectQueryChange = (query) => {
        setSelectQuery(query);
    };

    useEffect(() => {
        api.get('/settings/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSettings(response.data.data)
            }
        }).catch(error => {
            setSettings([])
        }).finally()
    }, [])

    const getShipping = (filterValues = {}) => {
        setFetchLoading(true);

        const todayDate = new Date().toISOString().split("T")[0];
        const yesterdayDate = new Date(Date.now() - 86400000).toISOString().split("T")[0];

        const queryParams = new URLSearchParams(filterValues).toString();

        const url = queryParams
            ? `/shipping/?${queryParams}`
            : `/shipping/?fromDate=${yesterdayDate}&toDate=${todayDate}&orderId=&trackingNumber=&carrier=&shippingMethod=`;

        api.get(url, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShippingData(response.data.data);
                setFilteredData(response.data.data);
                // if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setShippingData([]);
            setFilteredData([]);
            // if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    const getTrackingData = () => {
        api.get('/tracking/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setTrackingData(response.data.data);
            }
        }).catch(error => {
            console.error("Failed to fetch shipping data", error);
        });
    };

    const dynamicFilters = [
        { key: "orderId", label: "Order ID", type: "text" },
        {
            key: "TrackingNo", label: "Tracking No", type: "text"
        },
        {
            key: "carrier", label: "Carrier", type: "select", options: settings.filter(f => f.head.toLowerCase().includes('shipping')).map((p) => ({
                value: p.key,
                label: p.key
            }))
        },
        {
            key: "shippingMethod", label: "Shipping Method", type: "select", options: [
                { value: "Standard", label: "Standard" },
                { value: "Express", label: "Express" },
                { value: "Next-Day", label: "Next-Day" },
                { value: "Economy", label: "Economy" }
            ]
        },
    ];

    useEffect(() => {
        getShipping();
        getTrackingData();
    }, []);

    const openModal = (orderId, customerID) => {
        setSelectedOrderId(orderId);
        setSelectedCustomerId(customerID);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedOrderId(null);
    };

    const confirmAddToTracking = () => {
        console.log("Adding Order ID to Tracking: ", selectedOrderId);
        addToTracking(selectedOrderId, selectedCustomerId);
        closeModal();
        getTrackingData();
    };

    console.log(selectedCustomerId);
    const addToTracking = (orderId, customerID) => {
        api.post(`/tracking/`, { orderId, customerID }, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    console.log("Order updated successfully:", response.data);
                    getShipping();
                }
            })
            .catch(error => {
                console.error("Error updating order:", error);
            });
    };

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = shippingData.filter((data) => {
            const shippingDate = new Date(data.shippingDate);
            return shippingDate >= from && shippingDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = shippingData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.trackingNumber?.toLowerCase().includes(query?.toLowerCase()) ||
                data.carrier?.toLowerCase().includes(query?.toLowerCase()) ||
                data.shippingStatus?.toLowerCase().includes(query?.toLowerCase()) ||
                data.shippingMethod?.toLowerCase().includes(query?.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Shipping Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Order Id', 'Tracking Number', 'Shipping Date', 'Carrier', 'Shipping Status', 'Shipping Method', 'Shipping Cost', 'Address']],
            body: filteredData.map((od, i) => [
                i + 1,
                od.orderId,
                od.trackingNumber,
                od.shippingDate,
                od.carrier,
                od.shippingStatus,
                od.shippingMethod,
                od.shippingCost,
                `${od.address}\n${od.city}\n${od.state}`
            ])
        });
        doc.save('shipping.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((od, i) => ({
            'S.No': i + 1,
            'Order Id': od.orderId,
            'Tracking Number': od.trackingNumber,
            'Shipping Date': od.shippingDate,
            'Carrier': od.carrier,
            'Shipping Status': od.shippingStatus,
            'Shipping Method': od.shippingMethod,
            'Shipping Cost': od.shippingCost,
            'Address': `${od.address}, ${od.city}, ${od.state}`
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'shipping.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toggleFlyout = (invoice, from) => {
        setIsFlyoutVisible(!isFlyoutVisible)
        if (from === 'invoice') {
            setInvObj(invoice)
            setShippingObj(null)
        }
        else if (from === 'shipping') {
            setShippingObj(invoice)
            setInvObj(null)
        }
        else {
            setInvObj(null)
            setShippingObj(null)
        }
    };

    const handleApplyFilters = (appliedFilters) => {
        setFilters(appliedFilters);
        setIsModalOpen(false);
        getShipping(appliedFilters);
    };

    return (
        <>
            <Flyout isVisible={isFlyoutVisible} onClose={() => toggleFlyout({})} orderId={invObj?.orderId} shippingId={shippingObj?.id}>
            </Flyout>
            <FilterModal
                show={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onApply={handleApplyFilters}
                dynamicFilters={dynamicFilters}
                initialFilters={filters}
                selectQuery={selectQuery}
                setSelectQuery={handleSelectQueryChange}
            />
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Shipping</h5>
                        <div className="card-body">
                            {/* <CustomSelect /> */}
                            {/* Date Filters */}
                            {/* <div className="filter-section shadow p-2 rounded mb-2">
                                <div className="row d-flex">
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>From Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>To Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                        <button className=" mx-2 my-3 btn btn-primary" onClick={handleFilter}>
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>

                            </div> */}
                            <div className="card my-4 row">
                                <div className="row ">
                                    <div className="search-section m-3 col-8">
                                        <div className="form-check mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="directSearchCheckbox"
                                                checked={isCheckboxChecked}
                                                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                                            />
                                            <label className="form-check-label" htmlFor="directSearchCheckbox">
                                                Direct Search (Filter Table Data)
                                            </label>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Order ID, Tracking Number, Carrier Shipping Method"
                                            value={searchQuery}
                                            onChange={isCheckboxChecked ? (e) => handleSearch(e.target.value) : undefined}
                                            onClick={!isCheckboxChecked ? () => setIsModalOpen(true) : undefined}
                                        />
                                    </div>
                                    <div className="col m-3 ">
                                        {from === 'reports' && (
                                            <>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadPDF}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> PDF
                                                </button>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadCSV}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> CSV
                                                </button>
                                            </>
                                        )}

                                    </div>
                                </div>

                                <div className="table-wrapper">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th className="text-truncate">Order Id</th>
                                                <th className="text-truncate">Tracking Number</th>
                                                <th className="text-truncate">Shipping Date</th>
                                                <th className="text-truncate">Carrier</th>
                                                <th className="text-truncate">Shipping Status</th>
                                                <th className="text-truncate">Shipping Method</th>
                                                <th className="text-truncate">Shipping Cost</th>
                                                <th className="text-truncate">Address</th>
                                                {from === 'sales' && (<th className="text-truncate">Action</th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((od, i) => {
                                                const isTrackingAdded = trackingData.some(
                                                    tracking => tracking.orderId === od.orderId && tracking.customerId === od.customerId && tracking.description === 'delivered'
                                                );

                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td className="cursor-pointer" onClick={() => { toggleFlyout(od, 'invoice') }}>{od.orderId}</td>
                                                        <td className="cursor-pointer" onClick={() => { toggleFlyout(od, 'shipping') }}>{od.trackingNumber}</td>
                                                        <td>{od.shippingDate}</td>
                                                        <td>{od.carrier}</td>
                                                        <td>{od.shippingStatus}</td>
                                                        <td>{od.shippingMethod}</td>
                                                        <td>{od.shippingCost}</td>
                                                        <td>
                                                            {od.address}
                                                            <br />
                                                            {od.city}
                                                            <br />
                                                            {od.state}
                                                        </td>
                                                        <td>
                                                            {from === 'sales' && (
                                                                <>
                                                                    {isTrackingAdded ? (
                                                                        <button
                                                                            className="btn btn-success"
                                                                        >
                                                                            Add to Tracking
                                                                        </button>
                                                                    ) : (
                                                                        <button className="btn btn-primary"
                                                                            onClick={() => openModal(od.orderId, od.customerId)}
                                                                        >
                                                                            Added to Tracking
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                    </table>
                                </div>

                                {/* Modal */}
                                <Modal show={showModal} onHide={closeModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Tracking</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>
                                            Do you want to add the shipping #{selectedOrderId} to the tracking?
                                        </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={closeModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={confirmAddToTracking}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Shipping;
