import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { api, baseURL, customerReqConfig } from "../../include/api";

const CustomerGroupSlides = () => {
    const [fetchLoading, setFetchLoading] = useState(false);
    const [slideList, setSlideList] = useState([]);

    const getSlides = () => {
        setFetchLoading(true);
        api.get('/slide_show/', customerReqConfig).then(response => {
            if (response.status === 200) {
                setSlideList(response.data.data);
            }
        }).catch(error => {
            console.error("Error fetching slides", error);
        }).finally(() => {
            setFetchLoading(false);
        });
    };

    useEffect(() => {
        getSlides();
    }, []);

    // Filter slides for each container
    const bestSellerSlides = slideList?.filter(slide => slide.status === 'active' && slide.type === 'best seller');
    const hotDealsSlides = slideList?.filter(slide => slide.status === 'active' && slide.type === 'hot deals');
    const bottomSlides = slideList?.filter(slide => slide.status === 'active' && slide.type === 'day deals');

    return (
        <div className="container-fluide mt-3">
            <div className="row">
                {/* Left Container - Best Seller */}
                <div className="col-6 d-flex justify-content-center mb-3 ">
                    <div style={{ width: '900px', height: '333px' }}>
                        {fetchLoading ? (
                            <div className="w-100 bg-light" style={{ height: '300px' }} />
                        ) : (
                            <Carousel
                                autoPlay
                                infiniteLoop
                                interval={3000}
                                preventMovementUntilSwipeScrollTolerance
                                showThumbs={false}
                                transitionTime={100}
                                showStatus={false}
                            >
                                {bestSellerSlides?.map(slide => (
                                    <CarouselItem slide={slide} key={nanoid()} />
                                ))}
                            </Carousel>
                        )}
                    </div>
                </div>

                {/* Right Container - Hot Deals */}
                <div className="col-6 d-flex justify-content-center mb-3 ">
                    <div className="bg-white" style={{ width: "900px", height: '333px' }}>
                        {fetchLoading ? (
                            <div className="w-100 bg-light" style={{ height: '300px' }} />
                        ) : (
                            <Carousel
                                autoPlay
                                infiniteLoop
                                interval={3000}
                                preventMovementUntilSwipeScrollTolerance
                                showThumbs={false}
                                transitionTime={100}
                                showStatus={false}
                            >
                                {hotDealsSlides?.map(slide => (
                                    <CarouselItem slide={slide} key={nanoid()} />
                                ))}
                            </Carousel>
                        )}
                    </div>
                </div>

                {/* Bottom Container */}
                <div className=" mt-3 p-4 pt-0 pb-0">
                    <div className="bg-white">
                        {fetchLoading ? <div className="bg-light" style={{ width: '1200px', height: '300px' }} /> : <Carousel
                            autoPlay
                            infiniteLoop
                            interval={3000}
                            preventMovementUntilSwipeScrollTolerance
                            showThumbs={false}
                            transitionTime={100}
                            showStatus={false}
                        >
                            {bottomSlides?.map(slide => (
                                <CarouselItem slide={slide} key={nanoid()} />
                            ))}
                        </Carousel>}
                    </div>
                </div>
            </div>
        </div>
    );
};

const CarouselItem = ({ slide }) => {
    return (
        <div className="mx-2" style={{ height: '100%', flex: '1 1 0' }}>
            <img
                className="fit-cover w-100 h-100"
                src={slide.imageUrl ? `${baseURL}/${slide.imageUrl}` : ""}
                alt=""
            />
        </div>
    );
};

export default CustomerGroupSlides;
