// // Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app'
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// export const firebaseConfig = {
//     apiKey: import.meta.env.VITE_API_KEY,
//     authDomain: import.meta.env.VITE_AUTH_DOMAIN,
//     projectId: import.meta.env.VITE_PROJECT_ID,
//     storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
//     messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
//     appId: import.meta.env.VITE_APP_ID,
// }

// export const FIREBASE_VAPID_KEY = import.meta.env.VITE_VAPID_KEY

// console.log({ firebaseConfig, FIREBASE_VAPID_KEY })

// // Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const messaging = getMessaging(app)

// export async function getFcmToken() {
//     return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
//     // .then((currentToken) => {
//     //     if (currentToken) {
//     //         return currentToken
//     //     }
//     //     return null
//     // })
//     // .catch((err) => {
//     //     console.log('An error occurred while retrieving token - ', err)
//     //     return null
//     // })
// }

// onMessage(messaging, ({ notification }) => {
//     new Notification(notification.title, {
//         body: notification.body,
//         icon: notification.icon,
//     })
// })

// // if ("serviceWorker" in navigator) {
// //     navigator.serviceWorker
// //         .register("/firebase-messaging-sw.js")
// //         .then((registration) => {
// //             console.log("Service Worker registered:", registration);
// //         })
// //         .catch((error) => {
// //             console.error("Service Worker registration failed:", error);
// //         });
// // } else {
// //     navigator.serviceWorker
// //         .unregister("/firebase-messaging-sw.js")
// //         .then((registration) => {
// //             console.log("Service Worker unregistered:", registration);
// //         })
// //         .catch((error) => {
// //             console.error("Service Worker unregistration failed:", error);
// //         });
// // }

import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// REACT_APP_FIREBASE_PUBLIC_KEY
// REACT_APP_FIREBASE_API_KEY
// REACT_APP_FIREBASE_AUTH_DOMAIN
// REACT_APP_FIREBASE_PROJECT_ID
// REACT_APP_FIREBASE_STORAGE_BUCKET
// REACT_APP_FIREBASE_MESSAGING_SENDER_ID
// REACT_APP_FIREBASE_APP_ID
// REACT_APP_FIREBASE_MEASUREMENT_ID

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// console.log({ firebaseConfig });

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generateToken = async () => {
    let token = null
    const permission = await Notification.requestPermission();
    console.log(permission);
    if (permission === "granted") {
        // Add the public key generated from the console here.
        try {
            const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
            if (currentToken) {
                console.log({ currentToken });
                // return currentToken
                // Send the token to your server and update the UI if necessary
                // ...

                token = currentToken
            }
            else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    return token
}

onMessage(messaging, (payload) => {

    console.log({ payload });
})